'use client'

import ConfigProvider, {
    ConfigContext,
    ConfigConsumer,
    useConfig,
    defaultConfig,
} from './ConfigProvider'

export type { Config } from './ConfigProvider'

export {
    ConfigProvider,
    ConfigContext,
    ConfigConsumer,
    useConfig,
    defaultConfig,
}

export default ConfigProvider
