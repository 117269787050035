import { create } from 'zustand'

type CommonState = {
    currentRouteKey: string
    setCurrentRouteKey: (routekey: string) => void
}

export const baseInitialState = {
    currentRouteKey: '',
}

export const useBaseStore = create<CommonState>((set) => ({
    ...baseInitialState,
    setCurrentRouteKey: (routekey) => set({ currentRouteKey: routekey }),
}))