import classNames from "classnames";
import type { ComponentPropsWithRef, ElementType } from "react";
import { forwardRef } from "react";

export interface TableProps extends ComponentPropsWithRef<"table"> {
  asElement?: ElementType;
  borderlessRow?: boolean;
  compact?: boolean;
  hoverable?: boolean;
  overflow?: boolean;
  showPagination?: boolean;
  refScroll?: React.RefObject<HTMLDivElement> | null;
}

const Table = forwardRef<HTMLElement, TableProps>((props, ref) => {
  const {
    asElement: Component = "table",
    borderlessRow,
    children,
    className,
    compact = false,
    hoverable = true,
    overflow = true,
    refScroll,
    showPagination = false,
    ...rest
  } = props;

  const tableClass = classNames(
    Component === "table" ? "table-default" : "table-flex",
    hoverable && "table-hover",
    compact && "table-compact",
    borderlessRow && "borderless-row",
    className
  );

  return (
    <div
      ref={refScroll}
      className={classNames(
        showPagination == false
          ? "overflow-x-auto h-[400px] custom-table"
          : overflow && "overflow-x-auto h-[calc(100vh_-_350px)] custom-table"
        // overflow && "overflow-x-auto h-[calc(100vh_-_350px)] custom-table "
      )}
    >
      <Component className={tableClass} {...rest} ref={ref}>
        {children}
      </Component>
    </div>
  );
});

Table.displayName = "Table";

export default Table;
